import { Controller } from "@hotwired/stimulus"
import CableReady from 'cable_ready'


export default class extends Controller {

  static targets = [ "dialogBox" ]

  dialogBoxTargetConnected(event){
    const sessionTimeoutIntervalId = this.dialogBoxTarget.getAttribute("sessionTimeoutIntervalId");        
  }
  dialogBoxTargetDisconnected(event){
    const sessionTimeoutIntervalId = this.dialogBoxTarget.getAttribute("sessionTimeoutIntervalId");
    if (sessionTimeoutIntervalId){
      clearTimeout(parseInt(sessionTimeoutIntervalId));
    }
  }
  
  extendSession(event) {
    event.preventDefault();
    fetch(event.target.href)
      .then(()=>{
        //Cancel the interval
        const intervalId = this.dialogBoxTarget.getAttribute("intervalId");
        if (intervalId){
          clearTimeout(parseInt(intervalId));
        }
        

        //Dismiss the dialog        
        this.dialogBoxTarget.classList.add("hidden");
        
      })
      .catch(error => {
        console.error('Error:', error);
      });
      
  }
}